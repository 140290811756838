import { toast as showToast, Toaster as Sonner } from 'sonner'

import { Icon } from '@biogroup/icons'
import { cn } from '@biogroup/utils/cn'

type ToasterProps = React.ComponentProps<typeof Sonner>

function IconWrapper({
  className,
  children,
}: {
  className: string
  children: React.ReactNode
}) {
  return (
    <div
      className={cn(
        'flex h-4 w-4 items-center justify-center rounded-full text-white',
        className,
      )}
    >
      {children}
    </div>
  )
}

function Toaster({ className, ...props }: ToasterProps) {
  return (
    <Sonner
      className={cn('toaster group', className)}
      toastOptions={{
        classNames: {
          toast:
            'p-4 bg-background text-foreground border border-faded shadow-lg rounded-xl flex sm:flex-col lg:flex-row sm:items-start lg:items-center relative',
          title: 'font-headers font-bold text-md',
          icon: 'mb-auto mt-1 mr-0',
          description: 'text-muted-foreground text-sm pr-2',
          closeButton: 'absolute top-2 right-2 left-auto border-0 scale-125',
        },
      }}
      icons={{
        warning: (
          <IconWrapper className="bg-feedback-warning">
            <Icon name="alert" size="xs" />
          </IconWrapper>
        ),
        success: (
          <IconWrapper className="bg-feedback-success">
            <Icon name="check" size="xs" />
          </IconWrapper>
        ),
        info: (
          <IconWrapper className="bg-feedback-info">
            <Icon name="minus" size="xs" />
          </IconWrapper>
        ),
      }}
      {...props}
    />
  )
}

export { Toaster, showToast }
