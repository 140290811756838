import { withFaroErrorBoundary } from '@grafana/faro-react'
import {
  json,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteLoaderData,
} from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { useChangeLanguage } from 'remix-i18next/react'
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import { fallbackLng } from '@biogroup/i18n/config'
import { href as iconsHref } from '@biogroup/icons'
import uiStyleSheetUrl from '@biogroup/ui/styles.css?url'
import { Toaster } from '@biogroup/ui/toaster'
import { TooltipProvider } from '@biogroup/ui/tooltip'

import { getHideCreateAccountBanner } from '#app/utils/create-account-banner.ts'
import { Handle } from '#types/index'

import { CreateAccountDialogProvider } from './components/create-account-dialog.tsx'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { statusHandlers, UnexpectedError } from './components/errors.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getPatient, getUserRole } from './utils/auth.server.ts'
import { csrf } from './utils/csrf.server.ts'
import { getEnv } from './utils/env.server.ts'
import { FeaturesProvider, getFeatures } from './utils/features.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { i18nServer, localeCookie } from './utils/i18n.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.ts'
import { useNonce } from './utils/nonce-provider.ts'
import { getToast } from './utils/toast.server.ts'
import { useToast } from './utils/toaster.ts'

export const handle: Handle = {
  i18n: ['common', 'zod'],
}

export const links: LinksFunction = () => [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicons/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png',
  },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials',
  },
  { rel: 'icon', href: '/favicon.ico' },
  { rel: 'stylesheet', href: uiStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
]

export type RootLoaderType = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const t = await i18nServer.getFixedT(request)
  const locale = await i18nServer.getLocale(request)
  const { toast, headers: toastHeaders } = await getToast(request)
  const honeyProps = honeypot.getInputProps()
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken()
  const patient = await getPatient(request)
  const role = await getUserRole(request)
  const features = getFeatures(request.url)
  const hideCreateAccountBanner = await getHideCreateAccountBanner(request)

  return json(
    {
      locale,
      toast,
      honeyProps,
      csrfToken,
      patient,
      role,
      features,
      hideCreateAccountBanner,
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
      meta: {
        title: t('meta.title'),
      },
      ENV: getEnv(),
    },
    {
      headers: combineHeaders(
        { 'set-cookie': await localeCookie.serialize(locale) },
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
        toastHeaders,
      ),
    },
  )
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (data?.meta) {
    return [{ title: data.meta.title }]
  } else {
    return []
  }
}

export function Layout({ children }: { children: React.ReactNode }) {
  const nonce = useNonce()
  const data = useRouteLoaderData<typeof loader>('root')

  useToast(data?.toast)

  return (
    <html lang={data?.locale ?? fallbackLng}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Toaster closeButton />
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV ?? {})}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()

  useChangeLanguage(data.locale)

  const { t } = useTranslation()
  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: ['common', 'zod'] } }))

  return (
    <FeaturesProvider value={data.features}>
      <AuthenticityTokenProvider token={data.csrfToken}>
        <HoneypotProvider {...data.honeyProps}>
          <TooltipProvider delayDuration={300}>
            <CreateAccountDialogProvider>
              <Outlet />
            </CreateAccountDialogProvider>
          </TooltipProvider>
        </HoneypotProvider>
      </AuthenticityTokenProvider>
    </FeaturesProvider>
  )
}

export default withFaroErrorBoundary(App, {})

export function ErrorBoundary() {
  return (
    <GeneralErrorBoundary
      statusHandlers={statusHandlers}
      unexpectedErrorHandler={error => <UnexpectedError error={error} />}
    />
  )
}
